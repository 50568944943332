import React from "react";
import { SiteMeta } from "../general/seo";
import { TopBar, BottomBar } from "../general/extremeBars";
import NavBar from "../general/navbar";
import { RightGrid, LeftGrid, ShadeGrid, BaseGrid } from "./grids";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
  query {
    datalogsJson {
      product_page {
        base_background {
          childImageSharp {
            gatsbyImageData
          }
        }
        product_title
        products {
          title
          body
          slug
          images {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    file(relativePath: {eq: "productPageImages/productsBase.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export function ProductPage({ data }) {

  const PageData = data.datalogsJson.product_page;
  return (
    <>
   
      <SiteMeta
        title="Our Products - Psaltry International Company Limited"
        description="Psaltry Cassava Products"
      />
       <div className="dark:bg-gray-700">
      <TopBar />
      <NavBar />
      <div className="md:mx-36 mx-0 sm:mx-10 lg:mx-44">
        <div className="block my-10 ml-10 md:ml-0 lg:ml-0 xl:ml-0 text-gray-800 text-2xl dark:text-white">
          {PageData.product_title}
        </div>
        <img 
        src="/assets/images/subCircle2.png"
        alt="sub_circle"
        className="absolute left-0 "
        />

        <img 
        src="/assets/images/subCircle.png"
        alt="sub_circle"
        className="absolute right-0 top-2/3"
        />
        <LeftGrid data={PageData.products[0]} />
        <RightGrid data={PageData.products[1]} />
        <LeftGrid data={PageData.products[2]} />
      </div>
      <div className="bg-gray-700 w-full px-10 py-24 mb-16">
        <div className="md:mx-30 lg:mx-44">
          <ShadeGrid data={PageData.products[3]} />
        </div>
      </div>
      <div className="md:mx-30 px-10 lg:mx-32">
        <LeftGrid data={PageData.products[4]} />
      </div>
      <div className="bg-gray-800 mb-0">
        {/* <div className=""> */}
    <GatsbyImage 
    alt="background"
    image={PageData.base_background.childImageSharp.gatsbyImageData}
    style={{height: 'inherit', position: 'absolute', width: '100%'}}
    />
    {/* </div> */}
        <div className="md:mx-20 lg:mx-44 mx-0 sm:mx-8 py-12 z-30 px-0 relative">
          <BaseGrid data={PageData.products[5]} />
        </div>
      </div>
      <div className="bg-gray-50 mb-0 dark:bg-gray-400">
      <div className="md:mx-20 lg:mx-44 mx-0 sm:mx-8 py-24 z-30 px-0 relative ">
      <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen  px-8 md:px-0">
         All Products
         <div className="border-b-4 border-sgreen w-1/12 sm:my-2" />
      </div>
      <div className="mx-auto w-fit">
      <GatsbyImage 
      image={data.file.childImageSharp.gatsbyImageData}
      />
      </div>
      </div>
      </div>
      <BottomBar />
      </div>
    </>
  );
}


export default ProductPage;