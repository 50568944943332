import React from "react";
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";


export function LeftGrid ({data}) {
    const link = '/order/'+data.slug;
    return(<>
        <div className="md:flex md:mx-auto items-center mb-10 w-full container">
                    <div className="xl:w-1/2 md:w-1/2 w-full">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                            {data.title}
                        </div>
                        <div className="border-b-4 border-sgreen w-2/12 mt-4 mb-6" />
                        <div className="w-2/12" />
                        {data.body.map((content, index) => {
                            return(<>
                                <li role="contentinfo" className="focus:outline-none text-sm leading-6 mt-2 p-2 text-gray-600 text-justify dark:text-white">
                                {content}
                        </li>
                            </>)
                        })}
                        <div className="my-6">               
                           <button className="flex items-center shadow-sgreen shadow-lg shadow bg-sgreen  py-2 px-4 text-md font-medium text-white rounded-full ">
                               <Link to={link}> 
                                Order Product
                                </Link>
                            </button>                            
                        </div>
                    </div>
                    <div className="xl:w-3/6 md:w-1/2 w-full">
                        {/* <p role="contentinfo" className=" focus:outline-none text-base leading-6 mb-8 text-gray-600 md:mt-0 mt-10">
                            “Don’t get carried away with super-short paragraphs. The secret to one-sentence paragraphs is to use them like a spice: A pinch of cumin can make a meal more savory, but I don’t want to eat a plateful of the stuff.”
                        </p> */}
                        <div className="mx-auto my-4" style={{width: 352, height: 437}}>
                            <GatsbyImage 
                            image={data.images[0].childImageSharp.gatsbyImageData}
                            alt="Cassava Starch" className="" />
                            {/* <div className="flex items-center mt-8">
                                <p role="contentinfo" className="text-xl focus:outline-none font-semibold leading-5 text-gray-700">
                                    Picture description
                                </p>
                                <p role="contentinfo" className="text-xl focus:outline-none italic font-italic leading-5 pl-2 text-gray-700">
                                    Specification
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
        </>)
}

export function FoodsGrid({data}){
    return (
        <>
         <div className="md:flex md:flex-row-reverse mb-0 md:mx-auto items-center w-full container">
                <div className="xl:w-1/2 md:w-1/2 w-full pr-0 md:pr-10 mb-8 md:mb-0">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                         {data.title}
                        </div>
                        <div className="border-b-4 border-sgreen w-2/12 mt-4 mb-6" />
                        {data.content.map((content, index) => {
                            return(<>
                                <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-2 text-gray-600 px-2 text-justify dark:text-white">
                                {content}
                            </p>
                            </>)
                        })}
                </div> 
                    <div className="xl:w-3/6 md:w-1/2 w-full ">
                       <div className="mx-auto " style={{width: 352, height: 437}}>
                            {/* <GatsbyImage 
                            image={data.images[0].childImageSharp.gatsbyImageData}
                            alt="Cassava Starch" 
                            className=""
                             /> */}
                            <div className="flex flex-wrap my-auto">
                             <div className="w-1/2">
                                <GatsbyImage 
                                image={data.images[0].childImageSharp.gatsbyImageData}
                                alt="Cassava Starch" 
                                className="rounded-lg mr-2"
                                />
                             </div>
                             <div className="w-1/2 flex-col">
                             <GatsbyImage 
                                image={data.images[1].childImageSharp.gatsbyImageData}
                                alt="Cassava Starch" 
                                className="rounded-lg mb-2"
                                />
                                <GatsbyImage 
                                image={data.images[2].childImageSharp.gatsbyImageData}
                                alt="Cassava Starch" 
                                className="rounded-lg p-2"
                                />
                             </div>
                            </div>
                        </div>
                    </div> 

                    </div>
        </>
    )
}



export function RightGrid({data}){
    const link = '/order/'+data.slug;
    return (
        <>
         <div className="md:flex md:flex-row-reverse mb-10 md:mx-auto items-center w-full container">
                <div className="xl:w-1/2 md:w-1/2 w-full">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                          {data.title}
                        </div>
                        <div className="border-b-4 border-sgreen w-2/12 mt-4 mb-6" />
                        {data.body.map((content, index) => {
                            return(<>
                                <li role="contentinfo" className="focus:outline-none text-base leading-6 mt-2 text-gray-600 px-2 text-justify dark:text-white">
                                {content}
                            </li>
                            </>)
                        })}
                      <div className="my-6">  
                           <button className="flex items-center shadow-sgreen shadow-lg shadow bg-sgreen  py-2 px-4 text-md font-medium text-white rounded-full ">
                           <Link to={link}> 
                                Order Product
                            </Link>
                            </button>
                        </div>
                    </div> 
                    <div className="xl:w-3/6 md:w-1/2 w-full" >
                       <div className="mx-auto my-4" style={{width: 352, height: 437}}>
                            <GatsbyImage 
                            image={data.images[0].childImageSharp.gatsbyImageData}
                            alt="Cassava Starch" 
                            className=""
                             />
                        </div>
                    </div> 

                    </div>
        </>
    )
}

export function ShadeGrid({data}){
    const link = '/order/'+data.slug;
    return (
        <>
        <div className="md:flex md:flex-row-reverse mb-10 md:mx-auto items-center w-full container">
                <div className="xl:w-1/2 md:w-1/2 w-full">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-white">
                          {data.title}
                        </div>
                        <div className="border-b-4 border-white w-2/12 mt-4 mb-6" />
                        <ul className="focus:outline-none text-sm leading-6 mt-2 text-white px-2 text-justify">
                        {data.body.map((content, index) => {
                            return(<li key={index} role="contentinfo" class="list-disc my-2">
                                {content}
                        </li>)
                        })}
                        </ul>
                       <div className="my-6">
                           <button className="flex items-center shadow-sgreen shadow-lg shadow bg-sgreen  py-2 px-4 text-md font-medium text-white rounded-full ">
                           <Link to={link}>
                                Order Product
                                </Link>
                            </button>
                        </div>
                    </div> 
                    <div className="xl:w-3/6 md:w-1/2 w-full">
                        {/* <p role="contentinfo" className=" focus:outline-none text-base leading-6 mb-8 text-gray-600 md:mt-0 mt-10">
                            “Don’t get carried away with super-short paragraphs. The secret to one-sentence paragraphs is to use them like a spice: A pinch of cumin can make a meal more savory, but I don’t want to eat a plateful of the stuff.”
                        </p> */}
                        <div className="mx-auto my-4" style={{width: 352, height: 437}}>
                            <GatsbyImage 
                            image={data.images[0].childImageSharp.gatsbyImageData}
                            alt="Cassava Starch" 
                            className=""/>
                            {/* <div className="flex items-center mt-8">
                                <p role="contentinfo" className="text-xl focus:outline-none font-semibold leading-5 text-gray-700">
                                    Picture description
                                </p>
                                <p role="contentinfo" className="text-xl focus:outline-none italic font-italic leading-5 pl-2 text-gray-700">
                                    Specification
                                </p>
                            </div> */}
                        </div>
                    </div> 
                    </div>
        </>
    )
}

export function BaseGrid({data}){
    const link = '/order/'+data.slug;
    return (<>
    <div className="md:flex flex-wrap px-8 md:px-0 items-center w-full">
    <div className="xl:w-6/12 md:w-1/2 w-full">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-white">
                            {data.title}
                        </div>
                        <div className="border-b-4 border-white w-3/12 sm:my-2" />
                        {data.body.map((content, index) => {
                            return(
                        <li role="contentinfo" className="focus:outline-none text-base leading-6 mt-8 text-white 2xl:pr-24 xl:pr-0 text-justify">
                         {content}
                        </li>
                            )
                        })}  
                        <div className="my-6">
                           <button className="flex items-center shadow-sgreen shadow-lg shadow bg-sgreen  py-2 px-4 text-md font-medium text-white rounded-full ">
                           <Link to={link}> 
                                Order Product
                            </Link>
                            </button>
                        </div>                      
                    </div>
                    <div className="xl:w-6/12 md:w-1/2 w-full xl:pl-20 md:pl-10">
                    <div className="mx-auto md:inline lg:inline xl:inline hidden items-center my-4 block w-fit">
                            {data.images.map((image, index)=>{
                                return(
                                <GatsbyImage
                                image={image.childImageSharp.gatsbyImageData}
                                alt="masonry"
                                className="rounded-lg md:m-2 sm:m-0 m-1 lg:m-2"
                                />
                                )
                            })}
                        </div>
                        <div className="space-x-4 inline md:hidden lg:hidden xl:hidden items-center my-4 block w-fit flex ">
                            
                                <GatsbyImage
                                image={data.images[3].childImageSharp.gatsbyImageData}
                                alt="masonry"
                                className="rounded-lg w-full"
                                />
                                  <GatsbyImage
                                image={data.images[2].childImageSharp.gatsbyImageData}
                                alt="masonry"
                                className="rounded-lg w-full"
                                />
                              
                        </div>
                    </div>
                    
                </div>
    </>)
}